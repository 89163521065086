/* eslint-disable no-nested-ternary */
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import Card from 'components/UI/Card';
import { Field, FieldProps, Form, FormikProvider, FormikValues } from 'formik';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import Checkbox from 'components/UI/Checkbox/checkbox';
import LabelField from 'components/UI/LabelField';
import InputAutoSave from 'components/UI/InputAutoSave';
import { isFieldInObject } from 'utils/isFieldInObject';
import { useDispatch } from 'react-redux';
import { TID } from 'types/general';
import { useRouteMatch } from 'react-router';
import { useIsGroup } from 'domains/user/hooks';
import { isDisableEridToggle } from 'utils/isDisableEridToggle';
import NewSelect from 'components/UI/NewSelect';
import { everyGroups } from 'utils/statics/everyGroups';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { FiExternalLink } from 'react-icons/fi';
import { useUserInfo } from 'domains/user/model/selectors';
import { useDspLinks } from 'utils/useDspLinks/useDspLinks';
import css from './styles.module.scss';
import i18n from '../../../i18n';
import UploadImage from '../UploadImage';
import { useChangeClient } from '../model/hooks';
import { useClientInfo } from '../model/selectors';
import { fetchClientInfoById } from '../reduser';
import LoadingMain from './ui/Loading/LoadingMain';
import LoadingModeration from './ui/Loading/LoadingModeration';
import LoadingWidget from './ui/Loading/LoadingWidget';
import Translation from './ui/Translation';

const BasicSettings: FC = () => {
  const { data: user } = useUserInfo();
  const { data: clientInfo, isLoading } = useClientInfo();
  const dispatch = useDispatch();
  const { params } = useRouteMatch<TID>();
  const { isLocked } = useIsGroup();
  const {
    formik,
    savedValues,
    pastValues,
    onSwitchAutomoderation,
    onSwitchCheckErid,
    onDelLoadImg,
    onKeyHandler,
  } = useChangeClient();

  const { getJsonClientDspLink } = useDspLinks();

  useEffect(() => {
    dispatch(fetchClientInfoById(params.id));
  }, []);

  return (
    <div className={css.basicSettings}>
      <Helmet>
        <title>
          {i18n.t(`campaigns_page.campaign_settings.black_menu.Main_settings`)}
        </title>
      </Helmet>
      <div className={cn(css.header)}>
        <div className="container">
          <Breadcrumbs type="Client" xxhash={params.id} />
          <div className={css.status}>
            <h1 className={css.title}>
              <span>{clientInfo?.title}</span>
              {everyGroups(user, 'test') && (
                <a
                  href={getJsonClientDspLink()}
                  target="_blank"
                  rel="noreferrer"
                >
                  <TooltipPoratal hint="get json dsp">
                    <FiExternalLink size={16} />
                  </TooltipPoratal>
                </a>
              )}
            </h1>
          </div>
        </div>
      </div>
      <div className={css.body}>
        <FormikProvider value={formik}>
          <Form onKeyDown={onKeyHandler}>
            <div className={cn(css.container, 'container')}>
              <div className={css.grid}>
                {isLoading ? (
                  <Card noHover isUseAccess>
                    <LoadingMain />
                  </Card>
                ) : (
                  <Card noHover isUseAccess>
                    <h3 className={css.card_title}>
                      {i18n.t(`clients_page.client_edit.client_edit_tit`)}
                    </h3>

                    <div className={css.form}>
                      <Field name="idAgency">
                        {({ field, form }: FieldProps<FormikValues>) => (
                          <LabelField
                            label={i18n.t(
                              'clients_page.client_create.client_agency',
                            )}
                          >
                            <NewSelect
                              autoSubmit
                              form={form}
                              field={field}
                              selectAttributes={{
                                isDisabled: true,
                                options: [],
                                placeholder: i18n.t(
                                  'clients_page.client_create.client_agency',
                                ),
                                value: {
                                  label: 'test@test.ru',
                                  value: '4341B95E76ACA60F',
                                },
                              }}
                              heightCustom={32}
                              viewStyle="Horizontal"
                            />
                          </LabelField>
                        )}
                      </Field>
                      <InputAutoSave
                        label={i18n.t('clients_page.client_create.client_name')}
                        value={formik.values.title}
                        defaultValue={String(pastValues.title)}
                        name="title"
                        maxLength={120}
                        setFieldValue={formik.setFieldValue}
                        handlerInputEnter={() => null}
                        isError={!!formik.errors.title}
                        errorText={
                          typeof formik.errors?.title === 'string'
                            ? formik.errors?.title
                            : ''
                        }
                        isSaved={isFieldInObject(savedValues, 'title')}
                      />

                      <InputAutoSave
                        label={i18n.t('clients_page.client_create.client_site')}
                        value={formik.values.site}
                        defaultValue={String(pastValues.site)}
                        name="site"
                        maxLength={120}
                        setFieldValue={formik.setFieldValue}
                        handlerInputEnter={() => null}
                        isError={!!formik.errors.site}
                        isSaved={isFieldInObject(savedValues, 'site')}
                      />
                    </div>
                  </Card>
                )}

                {isLoading ? (
                  <Card noHover isUseAccess>
                    <LoadingModeration />
                  </Card>
                ) : (
                  <Card noHover isUseAccess>
                    <h3 className={css.card_title}>
                      {i18n.t('clients_page.client_edit.moderation_creatives')}
                    </h3>
                    <ToggleSwitch
                      disabled={isLocked}
                      onChange={onSwitchAutomoderation}
                      checked={formik.values.is_automoderation_enabled}
                      activateText={i18n.t(
                        'clients_page.client_edit.update_auto',
                      )}
                      deactivateText={i18n.t(
                        'clients_page.client_edit.update_hand',
                      )}
                    />
                    <div className={css.card_toggle_margin}>
                      <ToggleSwitch
                        disabled={
                          isLocked || isDisableEridToggle(clientInfo, 'CLIENT')
                        }
                        onChange={onSwitchCheckErid}
                        checked={formik.values.check_ERID}
                        activateText={i18n.t('creative.widget_text.check_ERID')}
                        deactivateText={i18n.t(
                          'creative.widget_text.check_ERID',
                        )}
                      />
                    </div>

                    <h4>
                      {i18n.t('clients_page.client_edit.categories_creatives')}
                    </h4>

                    <p className={css.helper}>
                      {i18n.t('clients_page.client_edit.switch_points')}
                    </p>

                    <div className={css.list}>
                      <Checkbox
                        label={i18n.t('clients_page.client_edit.animation')}
                        inputAttributes={{
                          type: 'checkbox',
                          name: 'is_animation_setting_enabled',
                          onChange: (e) => {
                            formik.handleChange(e);
                            formik.handleSubmit();
                          },
                          disabled: isLocked,
                          checked: formik.values.is_animation_setting_enabled,
                        }}
                      />
                      <Checkbox
                        label={i18n.t('clients_page.client_edit.jest')}
                        inputAttributes={{
                          type: 'checkbox',
                          name: 'is_violence_setting_enabled',
                          onChange: (e) => {
                            formik.handleChange(e);
                            formik.handleSubmit();
                          },
                          disabled: isLocked,
                          checked: formik.values.is_violence_setting_enabled,
                        }}
                      />
                      <Checkbox
                        label={i18n.t('clients_page.client_edit.erotic')}
                        inputAttributes={{
                          type: 'checkbox',
                          name: 'is_erotic_setting_enabled',
                          onChange: (e) => {
                            formik.handleChange(e);
                            formik.handleSubmit();
                          },
                          disabled: isLocked,
                          checked: formik.values.is_erotic_setting_enabled,
                        }}
                      />
                    </div>
                  </Card>
                )}
              </div>

              <div className={css.grid}>
                {isLoading ? (
                  <Card noHover isUseAccess>
                    <LoadingWidget />
                  </Card>
                ) : (
                  <Card noHover isUseAccess>
                    <UploadImage
                      withSubmit={formik.handleSubmit}
                      onDelLoadImg={onDelLoadImg}
                      remove_icon={formik.values.remove_icon}
                    />
                  </Card>
                )}
                <Card noHover isUseAccess>
                  <Translation />
                </Card>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

export default BasicSettings;
