/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from 'react';
import Select, {
  Props as SelectProps,
  GroupTypeBase,
  OptionTypeBase,
} from 'react-select';
import { TCampaignsFilter } from 'domains/campaigns/types';
import { FieldProps } from 'formik';
import { SelectField } from '../../../types/general';
import i18n from '../../../i18n';
import ErrorText from '../ErrorText';
import './style.scss';
import Label from '../Input/Label';
import { toolTipType } from '../Input/types';
import { DropdownIndicator } from './components/DropdownIndicator';
import { ClearIndicator } from './components/ClearIndicator';
import SelectWrapper from './components/SelectWrapper';

interface ISelect<
  OptionType extends OptionTypeBase,
  GroupType extends GroupTypeBase<OptionType>,
> extends SelectProps<OptionType, boolean, GroupType> {
  /** наличие ошибки */
  error?: boolean;
  /** Текст ошибки */
  errorText?: string;
  /** Скрывает иконку и компонента Error */
  errorHideIcon?: boolean;
  /** Получение функции для вывода в formik */
  form?: FieldProps['form'];
  /** Получение name от поля в formik */
  field?: FieldProps['field'];
  /** Атрибуты для Select */
  selectAttributes: SelectProps<OptionType, boolean, GroupType>;
  /** Кстомные Option */
  customOptions?: SelectProps<
    OptionType,
    false | true,
    GroupType
  >['components'];
  validateHandler?: (field: string) => void;
  /** Ref селекта */
  selectRef?: React.RefObject<Select<OptionType, boolean, GroupType>>;
  onChangeCallback?: {
    id: number;
    key: keyof TCampaignsFilter;
    callback: (id: number, value: string, k: keyof TCampaignsFilter) => void;
  };
  onCustomChange?: (value: string) => void;
  /** Автосохранение */
  autoSubmit?: boolean;
  /** label для select. Доступен только в  viewStyle="Vertical" */
  label?: string;
  /** Кастомынй класс */
  className?: string;
  /** Кастомный префикс для собственных стилей */
  classNamePrefix?: string;
  /** Строка результата поиска, когда ничего не найдено */
  noOptionsMessage?: (obj: { inputValue: string }) => string | null;
  setCurrentAudit?: React.Dispatch<React.SetStateAction<string>>;
  /** Стандартный вид */
  viewStyle: 'Vertical' | 'Horizontal';
  /** Высота селекта */
  heightCustom?: number;
  /** Выводит только выбранный Option */
  viewingOnly?: boolean;
  /** Тултип для label */
  labelTooltip?: toolTipType;
  /** Наличие крестика у multiOption */
  MultiValueRemove?: boolean;
}

export type MyOptionType = {
  [key: string]: string | undefined;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function NewSelect<
  OptionType extends OptionTypeBase,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
>({
  form,
  field,
  error,
  errorText,
  onChangeCallback,
  selectAttributes,
  viewStyle = 'Vertical',
  customOptions,
  autoSubmit,
  selectRef,
  onCustomChange,
  label,
  classNamePrefix = 'new_select',
  className,
  noOptionsMessage = () => i18n.t(`no_result`),
  errorHideIcon = false,
  setCurrentAudit,
  heightCustom = 0,
  viewingOnly,
  labelTooltip,
  MultiValueRemove = false,
}: ISelect<OptionType, GroupType>) {
  useEffect(() => {
    if (setCurrentAudit) {
      setCurrentAudit(field?.value);
    }
  }, [field]);

  const MultiValueRemoveCustom = MultiValueRemove
    ? {}
    : {
        MultiValueRemove: () => null,
      };

  return (
    <SelectWrapper
      className={className}
      heightCustom={heightCustom}
      isError={error && errorText !== ''}
      isDisabled={selectAttributes?.isDisabled}
      isVerticalStyle={viewStyle === 'Vertical'}
      isOnlyView={viewingOnly}
    >
      {label && viewStyle === 'Vertical' && (
        <Label
          label={label}
          forInput={selectAttributes?.id}
          tooltip={labelTooltip}
        />
      )}
      <Select<OptionType, boolean, GroupType>
        ref={selectRef}
        classNamePrefix={classNamePrefix}
        components={{
          ClearIndicator,
          DropdownIndicator,
          IndicatorSeparator: null,
          ...MultiValueRemoveCustom,
          ...customOptions,
        }}
        {...selectAttributes}
        placeholder={
          selectAttributes.placeholder ||
          i18n.t(`creative.create_creative.Please_select`)
        }
        onChange={(option) => {
          if (onChangeCallback && option) {
            const optionData = option as unknown as SelectField;
            const { key, id, callback } = onChangeCallback;
            callback(id, optionData.value, key);
          }
          if (selectAttributes.isMulti) {
            const options = option as unknown as SelectField[];
            form?.setFieldValue(
              `${field?.name}`,
              options.map(({ value }) => value).join(','),
            );
          } else {
            const optionData = option as unknown as SelectField;
            if (onCustomChange && optionData) {
              onCustomChange(optionData.value);
            }
            if (form && field) {
              form.setFieldValue(
                `${field.name}`,
                optionData ? optionData.value : '',
              );
              form
                .setFieldValue('width', 1)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .then(() => form.setFieldValue('height', 1))
                .then(() => form.validateForm());

              if (autoSubmit) form.handleSubmit();
            }
          }
        }}
        tabSelectsValue={false}
        closeMenuOnSelect={!selectAttributes.isMulti}
        hideSelectedOptions={!selectAttributes.isMulti}
        noOptionsMessage={noOptionsMessage}
        menuPortalTarget={document.getElementById('select-body-portal')}
      />
      {error && errorText !== '' && (
        <ErrorText text={errorText} hideIcon={errorHideIcon} />
      )}
    </SelectWrapper>
  );
}

export default NewSelect;
