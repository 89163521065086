import React, { FC } from 'react';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { useTranslation } from 'react-i18next';
import { everyGroups } from 'utils/statics/everyGroups';
import { useUserInfo } from 'domains/user/model/selectors';
import { useDspLinks } from 'utils/useDspLinks/useDspLinks';
import css from './styles.module.scss';
import { ReactComponent as FileCodeSvg } from '../../../../../assets/file-code.svg';

const Translation: FC = () => {
  const { t } = useTranslation();
  const { data: user } = useUserInfo();
  const { getStatusClientJsonDspLink } = useDspLinks();
  return (
    <div className={css.title}>
      <h1>{t(`translations.title_widget`)}</h1>
      {everyGroups(user, 'test') && (
        <a href={getStatusClientJsonDspLink()} target="_blank" rel="noreferrer">
          <TooltipPoratal hint="get status json dsp">
            <FileCodeSvg />
          </TooltipPoratal>
        </a>
      )}
    </div>
  );
};

export default Translation;
